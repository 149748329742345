<template>
  <!-- 数据总览页面布局（调用三个组件的页面） -->
  <div id="app">
    <div class="top">
      <div class="left">
        <ElectricityConsumptionRatio></ElectricityConsumptionRatio>
      </div>
      <div class="right">
        <ElectricityDataFigure>
          <template #monthlySelectSlot>
            <!-- 月份选择器 -->
            <div class="monthlySelectSlot">
              <!-- 遍历的单个按钮 -->
              <div v-for="(item, i) in monthlySelectData" :key="i">
                <i @click="monthlySub(item)" :class="item.iconLeft"></i>
                <input v-model="item.val" disabled type="text" :placeholder="item.name" />
                <i @click="monthlyAdd(item)" :class="item.iconRight"></i>
              </div>
            </div>
          </template>
          <template #dailySelectSlot>
            <div class="dailySelectSlot">
              <!-- 遍历的单个按钮 -->
              <div v-for="(item, i) in dailySelectData" :key="i">
                <i @click="dailySub(item)" :class="item.iconLeft"></i>
                <input v-model="item.val" disabled type="text" :placeholder="item.name" />
                <i @click="dailyAdd(item)" :class="item.iconRight"></i>
              </div>
            </div>
          </template>
        </ElectricityDataFigure>
      </div>
    </div>
    <div class="bottom">
      <ElectricityUseChart></ElectricityUseChart>
    </div>
  </div>
</template>

<script>
import ElectricityConsumptionRatio from "/src/components/Electricity/parts/ElectricityConsumptionRatio.vue";
import ElectricityUseChart from "/src/components/Electricity/parts/ElectricityUseChart.vue";
import ElectricityDataFigure from "/src/components/Electricity/parts/ElectricityDataFigure.vue";

export default {
  components: {
    ElectricityConsumptionRatio,
    ElectricityUseChart,
    ElectricityDataFigure,
  },
  props: {},
  data() {
    return {
      // 插槽展示的数据
      // 年用电量
      yearlyElectricityuse:'',
      // 月用电量
      monthlyElectricityuse:'',
      // 日用电量
      dailyElectricityuse:'',
      // 给后端发送的日期
      sendTime:'',
      // 年月日选择器
      dailySelectData: [
        {
          name: "年",
          iconLeft: "el-icon-arrow-left",
          val: new Date().getFullYear(),
          iconRight: "el-icon-arrow-right",
        },
        {
          name: "月",
          iconLeft: "el-icon-arrow-left",
          val: new Date().getMonth() + 1,
          iconRight: "el-icon-arrow-right",
        },
        {
          name: "日",
          iconLeft: "el-icon-arrow-left",
          val: new Date().getDate() - 1,
          iconRight: "el-icon-arrow-right",
        },
      ],
      // 年月选择器
      monthlySelectData: [
        {
          name: "年",
          iconLeft: "el-icon-arrow-left",
          val: new Date().getFullYear(),
          iconRight: "el-icon-arrow-right",
        },
        {
          name: "月",
          iconLeft: "el-icon-arrow-left",
          val: new Date().getMonth() + 1,
          iconRight: "el-icon-arrow-right",
        },
      ],
    };
  },
  created(){
  },
  methods: {
    // 加减按钮的点击事件(格式化年月日数据的方法)
    monthlySub(val){
      if (val.name == "年") {
        val.val--;
      } 
      // 判断月份，1月不能减
      else if (val.name == "月") {
        if (val.val > 1) {
          val.val--;
        }
      }
      // 获得数据方便进行回调函数的处理
      let time = this.monthlySelectData[0].val + "-" + this.monthlySelectData[1].val
      console.log(time);
    },
    monthlyAdd(val){
      if (val.name == "年") {
        val.val++;
      }
      // 判断月份，12月不能加
      else if (val.name == "月") {
        if (val.val < 12) {
          val.val++;
        }
      }
      // 获得数据方便进行回调函数的处理
      let time = this.monthlySelectData[0].val + "-" + this.monthlySelectData[1].val
      console.log(time);
    },
    dailySub(val){
      if (val.name == "年") {
        val.val--;
      } 
      // 判断月份，1月不能减
      else if (val.name == "月") {
        if (val.val > 1) {
          val.val--;
        }
      }
      // 判断日期,1号不能减
      else{
        if(val.val>1){
          val.val--
        }
      }
      // 获得数据方便进行回调函数的处理
      let time = this.dailySelectData[0].val + "-" + this.dailySelectData[1].val + "-" + this.dailySelectData[2].val
      console.log(time);
    },
    dailyAdd(val){
      // 涉及到日期的增加，需要计算当前选择月的天数
      // 将当前选择的年月
      let year = this.dailySelectData[0].val;
      let month = this.dailySelectData[1].val;
      // 获取当前年月前提下的每月总天数
      let day = new Date(year, month, 0).getDate();
      if (val.name == "年") {
        val.val++;
      }
      // 判断月份，12月不能加
      else if (val.name == "月") {
        if (val.val < 12) {
          val.val++;
        }
      }
      // 判断日期
      else{
        if(val.val < day){
          val.val++
        }
      }
      // 获得数据方便进行回调函数的处理
      let time = this.dailySelectData[0].val + "-" + this.dailySelectData[1].val + "-" + this.dailySelectData[2].val
      console.log(time);
    },

    // 根据选择的年月日信息，获取数据
    getYearData(){},
    getMonthData(time){},
    getDayData(time){},

    // 获取年月日数据的方法
    getFigureData(){

    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  .top {
    display: flex;
    justify-content: space-between;
    height: 45vh;
    box-sizing: border-box;
    .left {
      width: 50vw;
      color: #fff;
    }
    .right {
      width: 50vw;
      color: #fff;
      .monthlySelectSlot {
        display: flex;
        flex-direction: column;
        align-items: center;
        div {
          padding: 20px 20px 0;
          i {
            padding: 3px;
            border-radius: 7px;
            border: 1px solid rgba(29, 138, 226, 0.75);
            box-shadow: -2px -2px 2px rgb(85, 190, 231);
            cursor: pointer;
          }
          i:active {
            box-shadow: inset -2px -2px 2px rgb(85, 190, 231);
          }
          input {
            background-color: rgba($color: #23668d, $alpha: 0.75);
            height: 18px;
            width: 50px;
            border-radius: 95px;
            margin: 0 10px;
            color: aqua;
            text-align: center;
          }
          input::-webkit-input-placeholder {
            text-align: center;
          }
          input::-ms-input-placeholder {
            text-align: center;
          }
        }
      }
      .dailySelectSlot {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        div {
          padding: 10px 20px 0;
          i {
            padding: 3px;
            border-radius: 7px;
            border: 1px solid rgba(29, 138, 226, 0.75);
            box-shadow: -2px -2px 2px rgb(85, 190, 231);
            cursor: pointer;
          }
          i:active {
            box-shadow: inset -2px -2px 2px rgb(85, 190, 231);
          }
          input {
            background-color: rgba($color: #23668d, $alpha: 0.75);
            height: 15px;
            width: 50px;
            border-radius: 95px;
            margin: 0 10px;
            color: aqua;
            text-align: center;
          }
          input::-webkit-input-placeholder {
            text-align: center;
          }
          input::-ms-input-placeholder {
            text-align: center;
          }
        }
      }
    }
  }
  .bottom {
    width: 100%;
    margin-top: 10px;
    height: 45vh;
    box-sizing: border-box;
  }
}
</style>