<template>
    <!-- 右侧数据展示 -->
  <div id="topRight">
    <div class="title">
      <i class="iconfont icon-iotechgaoyapeidianfang"></i> 用电数据统计</div>
    <el-row>
      <div class="dataBox">
        <div class="titleStyle">年用电量（单位：Kw/h）</div>
        <div class="valueStyle">
          100
        </div>
      </div>
    </el-row>
    <el-row type="flex">
      <el-col :span="18">
        <div class="dataBox">
          <div class="titleStyle">月用电量（单位：Kw/h）</div>
          <div class="valueStyle">
            100
          </div>
        </div>
      </el-col>
      <!-- 右侧选择器 -->
      <el-col :span="6">
        <!-- 第二行子组件插槽 -->
        <div class="dataBox"><slot name="monthlySelectSlot"></slot></div>
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="18">
        <div class="dataBox">
          <div class="titleStyle">昨日用电量（单位：Kw/h）</div>
          <div class="valueStyle">
            123123
          </div>
        </div>
      </el-col>
      <!-- 右侧选择器 -->
      <el-col :span="6">
        <!-- 第三行子组件插槽 -->
        <div class="dataBox"><slot name="dailySelectSlot"></slot></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default{
    props: [ 'yearData', 'monthData', 'dayData'],
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "led regular";
  src: url("../../../assets/font/ds_digital/DS-DIGI.TTF");
}

#topRight {
  .title {
    padding: 5px;
    font-size: 20px;
    color: rgb(193, 207, 249);
    font-weight: 700;
  }
  .el-row {
    height: 130px;
    margin-bottom: 0px;
    .dataBox {
      height: 87%;
      border: 1px solid rgba($color: #1e83e0, $alpha: 0.55);
      box-shadow: 2px 2px 0px rgba(3, 92, 151, 0.5);
      box-sizing: border-box;
      margin-right: 10px;
      border-radius: 4px;
      .titleStyle {
        color: rgb(193, 207, 249);
        font-size: 18px;
        margin: 10px;
      }
      .valueStyle {
        float: right;
        font-family: led regular;
        font-size: 75px;
        margin-right: 7px;
        line-height: 60px;
        color: #50b7f3;
      }
    }
  }
}
</style>