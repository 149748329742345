<template>
  <div class="Echarts">
    <!-- 30天日用电量占比环形图 -->
    <!-- 接收一个有value，有name的对象（日期对应每一天的用电量） -->
    <div id="topLeft" style="width: 100%; height: 400px"></div>
  </div>
</template>

<script>
export default {
  name: "Echarts",
  methods: {
    myEcharts() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("topLeft"));
      // 指定图表的配置项和数据
      var option = {
        title: {
          left: '10%',
          text: '30天、日用电量占比',
          textStyle: {
            fontSize:"20",
            color: '#c1cff9',
          }
        },
        legend: {
          left: "10%",
          bottom: "0%",
          // 修改图例文字
          textStyle: {
              color: "#fff", // 图例文字颜色
              fontSize: "14",
            },
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            // 数据视图设置关闭
            // dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        series: [
          {
            name: "Nightingale Chart",
            type: "pie",
            radius: [50,100],
            // 修改主图片位置
            center: ["50%", "40%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 10,
            },
            data: [
              { value: 40, name: "01-01" },
              { value: 38, name: "01-02" },
              { value: 32, name: "01-03" },
              { value: 30, name: "01-04" },
              { value: 28, name: "01-05" },
              { value: 40, name: "01-06" },
              { value: 38, name: "01-07" },
              { value: 32, name: "01-08" },
              { value: 30, name: "01-09" },
              { value: 28, name: "01-10" },
              { value: 40, name: "01-11" },
              { value: 38, name: "01-12" },
              { value: 32, name: "01-13" },
              { value: 30, name: "01-14" },
              { value: 28, name: "01-15" },
              { value: 40, name: "01-16" },
              { value: 38, name: "01-17" },
              { value: 32, name: "01-18" },
              { value: 30, name: "01-19" },
              { value: 28, name: "01-20" },
              { value: 40, name: "01-21" },
              { value: 38, name: "01-22" },
              { value: 32, name: "01-23" },
              { value: 30, name: "01-24" },
              { value: 28, name: "01-25" },
              { value: 40, name: "01-26" },
              { value: 38, name: "01-27" },
              { value: 32, name: "01-28" },
              { value: 30, name: "01-29" },
              { value: 28, name: "01-30" },
            ],
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
  },
  mounted() {
    this.myEcharts();
  },
};
</script>

<style>
</style>