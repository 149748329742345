<template>
  <!-- 折柱混合图 -->
  <div id="bottom" style="width: 95%; height: 400px"></div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {
    initCharts() {
      //创建实例
      var myChart = this.$echarts.init(document.getElementById("bottom"));
      //设置参数
      var option = (option = {
        title: {
          left: '5%',
          text: '30天 用电量统计',
          textStyle: {
            color: '#c1cff9',
            fontSize:"20"
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar"] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        legend: {
          // 纵轴
          data: ["Evaporation", "Precipitation"],
        },
        xAxis: [
          {
            type: "category",
            // 横轴
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "Precipitation",
            min: 0,
            max: 250,
            interval: 50,
            axisLabel: {
              formatter: "{value} ml",
            },
          },
          // {
          //   type: "value",
          //   name: "Temperature",
          //   min: 0,
          //   max: 25,
          //   interval: 5,
          //   axisLabel: {
          //     formatter: "{value} °C",
          //   },
          // },
        ],
        series: [
          {
            name: "Evaporation",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return value + " ml";
              },
            },
            // 数据1
            data: [
              2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4,
              3.3,
            ],
          },
          {
            name: "Precipitation",
            type: "line",
            tooltip: {
              valueFormatter: function (value) {
                return value + " ml";
              },
            },
            // 数据2
            data: [
              2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0,
              2.3,
            ],
          },
          // {
          //   name: "Temperature",
          //   type: "line",
          //   yAxisIndex: 1,
          //   tooltip: {
          //     valueFormatter: function (value) {
          //       return value + " °C";
          //     },
          //   },
          //   data: [
          //     2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2,
          //   ],
          // },
        ],
      });
      //执行设置
      myChart.setOption(option);
    },
  },
  mounted() {
    this.initCharts();
  },
};
</script>

<style lang="scss" scoped>
</style>